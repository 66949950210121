import translate from "@/utils/translation"
import { useRouter } from "next-translate-routes"
import React from "react"
import { CashStack, Houses, HousesFill } from "react-bootstrap-icons"
import CurrencyFormat from "react-currency-format"
import { MdOutlineAutoGraph, MdOutlineConstruction } from "react-icons/md"
import styled from "styled-components"

const Performance = () => {
  const router = useRouter()

  return (
    <Wrapper className="mt-4 mt-md-0 ">
      <div className="line" />
      <div className="container">
        <div className="row">
          <div className="col-6 col-md-3 text-center">
            <MdOutlineAutoGraph
              size={30}
              className="mb-3 mt-2"
              color="#FB8501"
            />
            <p className="amount">
              {translate("homepage_performance_return")}%
            </p>
            <p> {translate("homepage_performance_return_title")}</p>
          </div>
          <div className="col-6 col-md-3 text-center">
            <CashStack size={30} className="mb-3 mt-2" color="#FB8501" />
            <p className="amount">
              €{translate("homepage_performance_invested")}
            </p>
            <p>{translate("homepage_performance_invested_title")}</p>
          </div>
          <div className="col-6 col-md-3  mt-5 mt-md-0 text-center">
            <MdOutlineConstruction
              size={30}
              className="mb-3"
              style={{ marginTop: "0.5rem" }}
              color="#FB8501"
            />
            <p className="amount">
              {translate("homepage_performance_under_construction")}
            </p>
            <p>
              {translate("homepage_performance_under_construction_title")}😉
            </p>
          </div>
          <div className="col-6 col-md-3 mt-5 mt-md-0 text-center">
            <HousesFill size={30} className="mb-3 mt-2" color="#FB8501" />
            <p className="amount">
              {translate("homepage_performance_pipeline")}
            </p>
            <p>{translate("homepage_performance_pipeline_title")}</p>
          </div>
        </div>
      </div>
      <div className="line mt-4" />
    </Wrapper>
  )
}

export default Performance

const Wrapper = styled.div`
  padding: 0rem 0 1.5rem;
  margin-top: 1rem;
  overflow: auto;

  .line {
    width: 100%;
    height: 1px;
    background-color: #f0f0f0;
    margin-bottom: 1.25rem;

    //fade left and right
    background: linear-gradient(to right, transparent, #f0f0f0, transparent);
  }

  p {
    color: #656565;
    margin: 0;
    font-weight: 300;
    font-size: 0.875rem;
  }

  .amount {
    font-size: 1.25rem;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }

  position: relative;
`
