import { Context } from "@/context/state"
import translate from "@/utils/translation"
import { Button, CircularProgress } from "@mui/material"
import Link from "next/link"
import { useRouter } from "next/router"
import React, { useContext, useEffect, useRef } from "react"
import styled from "styled-components"
import Item from "@/components/listings/overview/listingOverview/item/item"
import TagManager from "react-gtm-module"
import { useSession } from "next-auth/react"

const ListingMobile = ({ session }) => {
  const router = useRouter()

  //generate state from context
  const { state } = useContext(Context)

  var listings = [...(state?.listings ? state.listings : [])]

  const slider = useRef()

  const activeListings =
    listings?.filter(item => item.status === "bidding") ?? []

  return (
    <Wrapper className="" ref={slider}>
      <div className="container">
        <div className="d-flex justify-content-between row m-0">
          <div className="col-12">
            {activeListings.length === 1 ? (
              <h3>
                {activeListings.length}{" "}
                {translate("homepage_listings_recent_single")}
              </h3>
            ) : (
              <h3>
                {activeListings.length} {translate("homepage_listings_recent")}
              </h3>
            )}
            <Link href="/listings" className="d-none d-md-block">
              <Button variant="outlined" className="rounded-pill">
                {translate("homepage_listings_showMore")}
              </Button>
            </Link>
          </div>
          {activeListings.length > 0 ? (
            activeListings.map(listing => (
              <div className="mt-3 col-12 d-block d-lg-none" key={listing.uid}>
                <Item listing={listing} session={session} />
              </div>
            ))
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>
    </Wrapper>
  )
}

export default ListingMobile

const Wrapper = styled.div`
  padding-bottom: 3rem;

  h2 {
    font-size: 1.6rem;
  }

  @media (max-width: 768px) {
    .listing {
      margin: 0 !important;
    }

    h3 {
      font-size: 1.5rem;
    }

    .listing-item {
      max-width: 480px !important;
    }
  }
`
